
  import { defineComponent, ref, computed, watch, reactive, getCurrentInstance } from "vue"
  import { segmentColumns } from '@/view_scripts/table_columns/segment_columns'
  import { Contract } from "@/models/contracts";
  import { Household } from "@/models/households";
  import { Agency } from "@/models/agencies";
  import { FileMetaData as FMD} from "@/models/file_meta_datas";
  import { Segment, newSegmentObject, extendSegment } from "@/models/segments";
  import { localIndex, fetchRelated } from "@/utils/apiDataHelper"
  import { resourceUrl, fullName } from "@/utils/dataExtractors"
  import { stringSort } from "@/utils/helper"
  import { AttributesObject, ResourceObject, RelationshipsWithData, ResourceIdentifierObject } from '@/models/jsonapi'
  import { BvTableCtxObject } from "bootstrap-vue";
  import Tickets from "@/components/shared/Tickets.vue"
  import ContractKeyData from "@/components/shared/ContractKeyData.vue"
  import BkSuggestions from "@/components/shared/BKSuggestions.vue"
  import CareStayTable from "@/components/internal/CareStayTable.vue"
  import { watchForTicketIds } from "@/view_scripts/tickets"
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import { segmentSchema, uiSegmentSchema } from "@/view_scripts/form_schemas/segment"
  import { internalContractSchema, internalUiContractSchema } from "@/view_scripts/form_schemas/contract"
  import FileMetaData from "@/components/shared/FileMetaData.vue"
  import { newTicketAction } from "@/view_scripts/title_action_new_ticket";
  import TicketEdit from "@/components/shared/TicketEdit.vue"
  import { confirm, prompt } from '@/utils/interactionModals'
  import { provideRefreshData } from "@/utils/refreshData"

  interface Props {
    id: string
  }

  export default defineComponent({
    components: {
      Tickets,
      ContractKeyData,
      SchemaForm,
      FileMetaData,
      TicketEdit,
      BkSuggestions,
      CareStayTable
    },
    props: { id: { type: String, required: true } },
    setup(props: Props) {
      const root = getCurrentInstance().proxy
      const seller = ref<{[key:string]: any}>({})
      const index = ref<{[key:string]: any}>({})
      const selecteCareStayId = ref<string|null>(null)
      root.$store.dispatch('users/get_seller', { type: 'contracts', id: props.id }).then(r => {
        seller.value = r
      })

      const contract = computed<ResourceObject<string, Contract>>(() => {
        return Object.values(index.value).find((e: any) => {
          return e.type === 'contracts'
        })
      })

      const household = computed<ResourceObject<string, Household>>(() => {
        return Object.values(index.value).find((e: any) => {
          return e.type === 'households'
        })
      })

      const agency = computed<ResourceObject<string, Agency>>(() => {
        return Object.values(index.value).find((e: any) => {
          return e.type === 'agencies'
        })
      })

      const ticketIds = computed<string[]>(() => {
        return Object.values(index.value).reduce((res: any, entry: any) => {
          if (entry.type === 'tickets') res.push(entry.id)
          return res
        }, [])
      })

      const suggestionIds = computed<string[]>(() => {
        // if (contract.value === undefined) return []
        return (((contract.value?.relationships?.suggestions as RelationshipsWithData)?.data||[]) as ResourceIdentifierObject[]).map((s) => s.id)
      })

      const segments = computed<ResourceObject<string, Segment>[]>(() => {
        return Object.values(index.value).filter(obj => obj.type === 'segments')
      })

      const fmds = computed<ResourceObject<string, FMD>[]>(() => {
        return Object.values(index.value).filter(obj => obj.type === 'file_meta_datas')
      })

      const careStays = computed(() => {
        const tableItems = Object.values(index.value).filter(obj => obj.type === 'care_stays')
          .reduce((res: any[], cs: any) => {
          const cg = fetchRelated(cs, 'careGiverInstance.careGiver', index.value)
          cs.meta = {
            cGName: fullName(cg),
            contractId: props.id,
            agencyName: agency?.value?.attributes?.name || 'NA'
          }
          res.push(cs)
          return res
        },[])
        const result = stringSort(tableItems, (s) => s.attributes.billStart, false)
        if (!selecteCareStayId.value && result[0]) {
          selecteCareStayId.value = result[0].id
        }
        return result
      })

      const segmentTableItems = computed<ResourceObject<string, Segment>[]>(() => {
        const tableItems = segments.value.reduce((res: ResourceObject<string, Segment>[], seg: ResourceObject<string, Segment>) => {
          const csId = careStays.value?.find(c => c?.relationships?.segments?.data?.some(s => s?.id === seg?.id))?.id
          const sbId = seg.relationships.sellerBill?.data?.id
          const abId = seg.relationships.agencyBill?.data?.id
          if ( csId === selecteCareStayId.value ) {
            const cg = fetchRelated(seg, 'careGiverInstance.careGiver', index.value)
            seg.meta = { cGName: fullName(cg), sellerBillNr: 'NA' }
            if (sbId) { seg.meta.sellerBillNr = index.value[sbId].attributes.billNr }
            if (abId) { seg.meta.agencyBillNr = index.value[abId].attributes.billNr }
            res.push(seg)
          }
          return res
        },[])
        return stringSort(tableItems, (s) => s.attributes.start, false)
      })

      const careStaySelected = (csId: string) => selecteCareStayId.value = csId

      const keyData = computed<{[key:string]: any}>(() => {
        if (contract.value != undefined ) {
          const res: {[key:string]: any} = {...contract.value}
          if (res.attributes) {
            res.attributes.household = household.value
            res.attributes.agency = agency.value
          }
          return res
        }
        return {}
      })

      const showLoading = ref(false)
      const totalSegments = computed<number>(() => segmentTableItems.value.length)
      const editSegmentModal = ref()
      const editCareStayModal = ref()
      const editContractModal = ref()
      const csActionModal = ref()
      const newTicketModal = ref()
      const careStayToEdit = ref<ResourceObject<string, CareStay>>()
      const segmentToEdit = ref<ResourceObject<string, Segment>>()
      const contractToEdit = ref<ResourceObject<string, Contract>>()
      const contractProcessModal = ref()
      const contractProcessData = reactive<{[key: string]: any}>({
        reasons: [],
        customReason: '',
        selectedReason: null,
        end: '',
        displayEndInput: false,
      })

      const refreshData = () => {
        const queryParams = {
          'filter[id]': props.id,
          'include': 'agency,care_stays.care_giver_instance.care_giver,care_stays.segments.seller_bill,care_stays.segments.agency_bill,file_meta_datas,household,tickets',
          'fields[agencies]': 'name',
          'fields[care_givers]': 'first_name,last_name',
          'fields[households]': 'designation',
          'fields[tickets]': 'subject,messages',
          'fields[file_meta_datas]': 'filename,length',
          'fields[care_stays]': 'all',
          'fields[segments]': 'all',
          'fields[seller_bills]': 'bill_nr',
          'fields[agency_bills]': 'bill_nr',
          //'fields[care_stays]': 'bill_start,bill_end,arrival,departure,price,dub_hh,dub_cr,night_care,drivers_license,stage',
        }
        root.$store.dispatch('contracts/load2', queryParams).then(processServerData)
      }

      provideRefreshData(refreshData)

      const processServerData = (response: any) => index.value = localIndex(response)

      const lastSegment = computed(() => {
        if (segments.value.length === 0) return null
        if (segments.value.length === 1) return segments.value[0]
        return segments.value.sort(sortSegmentsByStart)[0]
      })

      const sortSegmentsByStart = (a, b) => {
        return ((new Date(b.attributes.start) as any) - (new Date(a.attributes.start) as any))
      }

      const editSegment = (data: any) => {
        segmentToEdit.value = JSON.parse(JSON.stringify(data.item))
        editSegmentModal.value.show()
      }

      // const deleteSegment = async (data: any) => {
      //   const atts = data.item.attributes
      //   if (typeof(atts.agencyBillPaid) === 'boolean' && !atts.agencyBillPaid && typeof(atts.sellerBillPaid) === 'boolean' && !atts.sellerBillPaid) {
      //     const killIt = await confirm(root, 'Soll der Abschnitt gelöscht werden?')
      //     if (killIt) {
      //       root.$store.dispatch('segments/delete', data.item.id)
      //         .then(refreshData)
      //     }
      //   } else {
      //     prompt(root, 'Der Abschnitt ist bereits abgerechnet und kann nicht gelöscht werden.')
      //   }
      // }

      const columns = ref(segmentColumns({editSegment}))

      const saveSegment = async (bvModalEvent: any) => {
        bvModalEvent.preventDefault()
        if (segmentToEdit.value?.id === 'new') {
          root.$store.dispatch('segments/create', segmentToEdit.value)
        } else if (segmentToEdit.value?.id) {
          root.$store.dispatch('segments/edit', { id: segmentToEdit.value.id, body: segmentToEdit.value })
        }
        refreshData()
        root.$nextTick(() => editSegmentModal.value.hide())
      }

      const saveContract = async (bvModalEvent: any) => {
        bvModalEvent.preventDefault()
        await root.$store.dispatch('contracts/edit', { id: props.id, body: contractToEdit.value })
        refreshData()
        root.$nextTick(() => editContractModal.value.hide())
      }

      const updateFiles = (data:any) => {
        root.$store.dispatch('contracts/edit', { id: props.id, body: data }).then(() => refreshData())
      }

      const ticketCreated = () => {
        refreshData()
        newTicketModal.value.hide()
      }

      const nextSegment = () => {
        if (lastSegment.value && lastSegment.value.attributes) {
          // const {updatedAt, createdAt, start, end, agencyBillPaid, sellerBillPaid, ...rest} = lastSegment.value.attributes
          const extSegment = extendSegment(lastSegment.value)
          if (extSegment?.attributes) {
            extSegment.attributes.contract_id = props.id
            const cgiId = ((lastSegment.value?.relationships?.careGiverInstance as RelationshipsWithData)?.data as ResourceIdentifierObject)?.id
            if (cgiId) extSegment.attributes.care_giver_instance_id = cgiId
          }
          return extSegment
        } else {
          return newSegmentObject(props.id)
        }
      }

      const titleActions = computed(() => {
        //if (!contract.value?.attributes) return []
        return [
          {
            title: 'Bearbeiten',
            icon: 'pencil-alt',
            tooltip: 'Vertrag bearbeiten',
            onClick: () => {
              const keys = Object.keys(internalContractSchema.properties.attributes.properties)
              const attrs:Contract = keys.reduce((res, k) => {
                res[k] = contract.value?.attributes?.[k]
                return res
              }, {} as Contract)
              contractToEdit.value = {attributes: attrs, type: 'contracts'}
              editContractModal.value.show()
            }
          },
          newTicketAction(newTicketModal),
          {
            title: 'Aktionen',
            children: [
              // {
              //   title: 'Neuen Pflegeabschnitt erstellen',
              //   // tooltip: 'Neuen Pflegeabschnitt erstellen',
              //   onClick: () => {
              //     segmentToEdit.value = nextSegment(),
              //     editSegmentModal.value.show()
              //   }
              // },
              {
                title: 'Vertrag beenden',
                tooltip: 'Vertrag beenden. (Kunde hat gekündigt)',
                onClick: terminateContract
              },
              {
                title: 'Vertrag Abbrechen',
                tooltip: 'Vertrag nicht zu Stande gekommen. Wird entfernt',
                onClick: abortContract
              },
            ]
          },
          // {
          //   title: active ? 'Deaktivieren' : 'Aktivieren',
          //   title: active ? 'Deaktivieren' : 'Aktivieren',
          //   tooltip: active ? 'Deaktivieren' : 'Aktivieren',
          //   icon: active ? 'times' : 'check',
          //   onClick: () => ContractsRepository.edit(props.id, { attributes: { active: !active }})
          // },
          // {
          //   title: 'Archivieren',
          //   icon: 'archive',
          //   title: 'Archivieren',
          //   tooltip: 'Archivieren',
          //   onClick: () => alert('Archivieren')
          // },
        ]
      })

      const abortContract = async () => {
        if (segments.value.length > 1) {
          prompt(root, "Nur Verträge mit maximal einem Pflegeabschnitt können abgebrochen werden.")
        } else {
          contractProcessData.usecase = 'abort'
          contractProcessData.title = 'Vertrag abbrechen'
          contractProcessData.text = 'Der Vertrag wird gelöscht. Der Vorschlag wird entsprechend aktualisiert.'
          contractProcessData.reasons = []
          const listQuery = {'filter[sys_id]': 'contract_abort_reasons',include: 'sublists'}
          await root.$store.dispatch('lists/load', listQuery)
          contractProcessModal.value.show()
          contractProcessData.reasons = root.$store.state.lists.data[0].attributes.sublists.map((rr)=>{
            return {value: rr.id, text: rr.attributes?.name}
          }).concat({value: 'new', text: "Eigene Begründung angeben..."})
          contractProcessData.displayEndInput = false
          contractProcessData.reasons.unshift({value: null, text: 'Abbruchsgrund wählen'})
        }
      }

      const terminateContract = async () => {
        contractProcessData.usecase = 'terminate'
        contractProcessData.title = 'Vertrag beenden'
        contractProcessData.text = 'Der Vertrag wird archiviert. Falls der Kunde generell gekündigt hat, bitte auch den Lead resp. Haushalt absagen. Falls nur der Vertrag gekündigt worden ist, kann die Agentur dieses Vertrages zukünftige Stellenausschreibungen nicht mehr verfolgen. Um eine neue BK zu finden, muss die Stellenausschreibung reaktiviert werden. Dazu bitte die Ausschreibung aktualisieren (Anreisedatum) und reaktivieren.'
        contractProcessData.reasons = []
        contractProcessData.end = ''
        const listQuery = {'filter[sys_id]': 'contract_cancel_reasons',include: 'sublists'}
        await root.$store.dispatch('lists/load', listQuery)
        contractProcessData.reasons = root.$store.state.lists.data[0].attributes.sublists.map((rr)=>{
          return {value: rr.id, text: rr.attributes?.name}
        }).concat({value: 'new', text: "Eigene Begründung angeben..."})
        contractProcessData.reasons.unshift({value: null, text: 'Abbruchsgrund wählen'})
        contractProcessData.displayEndInput = !allSegmentsClosedTest()
        contractProcessModal.value.show()
      }

      const processContract = (usecase: string) => {
        let reason, data
        reason = contractProcessData.reasons.find((r) => r.value == contractProcessData.selectedReason)
        if (contractProcessData.selectedReason  === 'new') { reason = {text: contractProcessData.customReason} }
        if (usecase === 'abort') {
          data = {usecase, abort_reason: reason.text}
        } else if (usecase === 'terminate') {
          data = {usecase, termination_reason: reason.text, end: contractProcessData.end}
        }
        updateContract(data)
      }

      const updateContract = (data: any) => {
        showLoading.value = true
        root.$store.dispatch('contracts/edit', { id: props.id, body: data })
          .then(() => refreshData())
          .finally(() => {
            showLoading.value = false
          })
      }

      const allSegmentsClosedTest = () => {
        return segments.value.filter((s) => !s.attributes?.end).length === 0
      }

      refreshData()

      return {
        showLoading,
        contract,
        household,
        segmentTableItems,
        careStays,
        fmds,
        keyData,
        totalSegments,
        columns,
        titleActions,
        ticketIds,
        suggestionIds,
        resourceUrl,
        editSegmentModal,
        editContractModal,
        segmentSchema,
        uiSegmentSchema,
        internalContractSchema,
        internalUiContractSchema,
        saveSegment,
        saveContract,
        segmentToEdit,
        contractToEdit,
        updateFiles,
        refreshData,
        newTicketModal,
        ticketCreated,
        lastSegment,
        contractProcessModal,
        contractProcessData,
        processContract,
        seller,
        index,
        careStaySelected
      }
    }
  })
